import React, { useState, useEffect } from 'react';

import { getCloudinaryUrl } from './helper';

// import dateFormat from 'dateformat';


export default function Entity(props) {

  const {mode, type, entity, isSelf, isPortraitMode, isPulsing,
    onSelect, // Section & Chat
    handleUpdateEntity, onNext // Central
  } = props;

  /* Load, Section & Chat */

  const size = mode === "load" ? Math.random() * 20 : 40; // person ? person.size : Math.random() * 20;
  const brightness = 1 // person ? person.brightness : Math.random();
  

  /* Load */
  
  let x, y, translateX, translateY;
  if (entity) {
    
    x = entity.x;
    y = entity.y;

  } else if (type === "star") { // For stars, seed in the middle, and move to the edges
    
    x = 50 + (Math.random() * 10 - 5) * 1;
    y = 50 + (Math.random() * 10 - 5) * 1;
  
    // distance from center
    const X = Math.abs(x - 50);
    const Y = Math.abs(50 - y);

    translateX = 50 * (x > 50 ? 1 : -1) * (Y > X ? X / Y : 1) - X;
    translateY = 50 * (50 > y ? -1 : 1) * (X > Y ? Y / X : 1) - Y;

  } else { // For neurons, seed at the edges, and move to the middle
    
    let seed = Math.random();
    x = seed < 0.5 ? Math.random() * 100 : seed < 0.75 ? 0 : 100;
    y = seed > 0.5 ? Math.random() * 100 : seed > 0.25 ? 0 : 100;

    translateX = 50 - x;
    translateY = 50 - y;

  }
  

  /* Section & Chat */

  // Hovering animates several elements at once
  const [isInteracting, setIsInteracting] = useState(false);
  const handleHover = () => onSelect ? setIsInteracting(true) : null;
  const handleLeave = () => onSelect ? setIsInteracting(false) : null;

  // Clicking on the entity
  const handleClick = (event) => {
    event.stopPropagation();
    if (entity && onSelect) {
      console.log(entity);
      onSelect(entity);
    }
  };


  /* Central */

  // Loading low-res and then high-res picture
  const [isPictureLoaded, setIsPictureLoaded] = useState(false);
  const [prevPicture, setPrevPicture] = useState('');
  
  // Editing
  const [editingField, setEditingField] = useState(null);
  const [editedValues, setEditedValues] = useState({});

  useEffect(() => {
    if (mode === "central") {
      if (entity.picture && entity.picture !== prevPicture) {
        setIsPictureLoaded(false);
        setPrevPicture(entity.picture);
      }
      setEditedValues({
        name: entity.name || '',
        location: entity.location || '',
        bio: entity.bio || '',
        question: entity.question || '',
        update: entity.update || ''
      });
    }
  }, [mode, entity, prevPicture]);

  const handleEditClick = (field) => isSelf || field === 'update' ? setEditingField(field) : null;
  const handleChange = (field, value) => setEditedValues(prev => ({ ...prev, [field]: value }));
  const handleKeyDown = (e, field) => e.key === 'Enter' && field !== 'bio' && field !== 'update' ? handleEditConfirmed(field, e) : null;
  const handleEditConfirmed = (field, e=null) => {
    setEditingField(null);
    if (e) e.preventDefault();
    if (editedValues[field] !== entity[field] && editedValues[field]) handleUpdateEntity(field, editedValues[field]);
  };

  const renderEditableField = (field, displayValue, placeholder) => {
    const isEditing = editingField === field;
    const value = editedValues[field] || '';

    if (isEditing) {
      const commonProps = {
        value: value,
        onChange: (e) => handleChange(field, e.target.value),
        onBlur: () => handleEditConfirmed(field),
        onKeyDown: (e) => handleKeyDown(e, field),
        autoFocus: true,
        className: "text-lg"
      };
      return field === 'bio' || field === 'update' 
      ? (<textarea style={{marginTop: '6px', marginBottom: '6px'}} rows={field === 'bio' ? 4 : 10} {...commonProps} />) 
      : (<input style={{marginTop: '6px', marginBottom: '6px'}} type="text" {...commonProps} />);

    } else {
      return (
        <div onClick={() => handleEditClick(field)} className={`whitespace-pre-line overflow-ellipsis ${isSelf || field === 'update' ? 'fade' : ''}`}>
          {(field === 'name' || field === 'question') ? (
            <>
              <div className="flex flex-col justify-center h-14">
                {displayValue.includes(' ') ? (
                  <>
                    <div className="block text-xl font-bold">
                      {displayValue.split(' ').slice(0, displayValue.split(' ').length / 2).join(' ')}
                    </div>
                    <div className="block m-0 w-full foreground-gradient-1 text-lg italic font-normal pr-1">
                      {displayValue.split(' ').slice(displayValue.split(' ').length / 2).join(' ')}
                    </div>
                  </>
                ) : (
                  <div className="block text-xl font-bold">
                    {displayValue}
                  </div>
                )}
              </div>
            </>
          ) : displayValue || isSelf || field === 'update' ? (
            <div className={`text-lg ${field === 'bio' ? 'foreground-gradient-2 italic pr-1' : ''} ${!entity[field] ? 'text-pulse' : ''}`}>
              {displayValue || placeholder}
            </div>
          ) : (<></>)}
        </div>
      );
    }
  };

  return (
    <>
      {mode === "load" && (
        <div className="absolute" style={{ top: `${isPortraitMode ? x : y}%`, left: `${isPortraitMode ? y : x}%`, margin: '0.5%', transform: 'translate(-50%, -50%)' }}>
          <div className="absolute animate-move" style={{
            '--duration': `${Math.random() * 1 + 0.33}s`,
            animationDelay: `${Math.random() * 0.5}s`,
            // dividing by 2 as it's for half the screen
            "--translateX": `${isPortraitMode ? translateY / 1 : translateX / 2}vw`,
            "--translateY": `${isPortraitMode ? translateX / 2 : translateY / 1}vh`
          }}>
            <div 
              className={`${type === "star" ? 'orb' : 'diamond'}`} 
              style={{
                width: `${size}px`,
                height: `${size}px`,
                opacity: brightness
              }} 
            />
          </div>
        </div>
      )}

      {(mode === "section" || mode === "chat") && (
        <div className={`w-full flex items-center ${mode === "section" ? 'mt-1.5 p-2.5 flex-col' : `${isPortraitMode ? 'flex-col' : (isSelf ? 'flex-row-reverse' : 'flex-row')}`}`}>
          
          {/* Shape */}
          <div onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleLeave}
            className={`mx-2 flex-shrink-0 ${type === "star" ? 'plain-orb' : 'plain-diamond'} ${onSelect ? 'ease' : ''} ${isPulsing && !isInteracting ? 'pulse' : ''} ${isInteracting ? 'glow-interact' : ''}`}
            style={{width: `${type === "star" ? size : size - 10}px`, height: `${type === "star" ? size : size - 10}px`, opacity: brightness, overflow: 'hidden'}} 
          >
            {entity.picture && <img src={getCloudinaryUrl(entity.picture, size * 4, size * 4, 'g_face')} alt={`Profile of ${entity.name}`} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>}
          </div>

          {/* Text */}
          <div onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleLeave}
            className={`mt-1.5 flex flex-col justify-center items-center p-1.5 rounded-lg overflow-hidden text-white text-sm text-center break-words whitespace-normal w-full max-w-[160px] ${onSelect ? 'ease' : ''} ${isInteracting ? 'fade-interact' : ''}`}
          >
            <span className={`line-clamp-4 overflow-ellipsis ${!isPortraitMode && mode === "chat" && entity.question ? (isSelf ? 'text-right' : 'text-left') : ''}`}>
              {entity.name || entity.question}
            </span>
            {entity.bio && <span className="foreground-gradient-1 text-xs italic pr-1 line-clamp-3 overflow-ellipsis">{entity.bio}</span>}
            {entity.subscribers && <span className="foreground-gradient-1 text-xs italic pr-1 line-clamp-1 overflow-ellipsis">{entity.subscribers} {entity.subscribers === 1 ? "asker" : "askers"}</span>}
            {entity.constellation && <span className="foreground-gradient-2 text-xs italic pr-1 line-clamp-2 overflow-ellipsis">#{entity.constellation}</span>}
          </div>

        </div>
      )}

      {mode === "central" && (
        <div className='flex flex-col items-center max-w-[400px] w-full'>
          
          {/* Details */}
          <div className="text-center flex flex-col justify-start after-line">
            {type === "star" ? 
              renderEditableField('name', entity.name, "Add name") : 
              renderEditableField('question', entity.question, "Add question")}
          </div>

          {/* <div className="mb-4 text-center flex flex-col justify-start">
            <p onClick={type === "star" ? () => { startUpdatingEntity("name"); } : () => {}} className={`text-xl font-bold text-white mb-1 overflow-ellipsis after-line ${isSelf ? 'fade' : ''}`}>
              <span className="block">{type === "star" ? entity.name.split(' ')[0] : entity.question.split(' ').slice(0, entity.question.split(' ').length / 2).join(' ')}</span>
              <span className="block m-0 w-full foreground-gradient-1 text-lg italic font-normal text-center">
                {type === "star" ? entity.name.split(' ').slice(1).join(' ') : entity.question.split(' ').slice(entity.question.split(' ').length / 2).join(' ')}
              </span>
            </p>
            {entity.phone && <p onClick={() => { setErrorMessage("Please write to us at hi@VERSEisbetter.com to update your phone number.") }}
              className={`text-lg foreground-gradient-1 mb-1 italic ${isSelf ? 'fade' : ''}`}>
              {`+${entity.phone.replace(/(\d{1,3})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4')}`}
            </p>}
          </div> */}

          {/* Cover & Profile picture */}
          {type === "star" && (
            <div className="relative w-full aspect-square m-4">

              {/* Glow */}
              <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                <div className="w-[70.6%] h-[70.6%] plain-diamond"></div>
              </div> 
              
              {/* Cover */}
              <div className="absolute inset-0 w-full h-full overflow-hidden">
                {/* {entity.cover && ( */}
                <img
                  src={getCloudinaryUrl(entity.cover || "web/default-cover", 600, 600, 'g_auto')}
                  alt="Cover"
                  className="w-full h-full object-cover"
                  style={{
                    clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)'
                  }}
                />
                {/* )} */}
              </div>
              
              {/* Profile picture */}
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/5 h-3/5">
                {entity.picture && (
                  <>
                    <img
                      src={getCloudinaryUrl(entity.picture, 160, 160, 'g_face')}
                      alt={`Profile of ${entity.name}`}
                      className="absolute top-0 left-0 w-full h-full object-cover plain-orb"
                    />
                    <img
                      src={getCloudinaryUrl(entity.picture, 320, 320, 'g_face')}
                      alt={`Profile of ${entity.name}`}
                      className={`absolute top-0 left-0 w-full h-full object-cover ease cursor-default plain-orb ${ // cursor-default until we allow changing the picture
                        isPictureLoaded ? 'opacity-100' : 'opacity-0'
                      }`}
                      onLoad={() => setIsPictureLoaded(true)}
                    />
                  </>
                )}
              </div>
            </div>
          )}

          {/* Details */}
          <div className="mb-8 w-full text-center relative" style={{height: !isPortraitMode ? `calc(100vh - 500px)` : 'auto'}}>
            {type === "star" ? (
              <div className="flex flex-col items-center">
                {renderEditableField('location', entity.location, "Add location")}
                {renderEditableField('bio', entity.bio, "Add bio")}
              </div>
            ) : 
              <div className={`p-4 ${!isPortraitMode ? 'absolute inset-0 overflow-auto scrollbar-hide' : ''}`}>
                {renderEditableField('update', entity.update, "<Write answer>")}
              </div>
            }
            {!isSelf && type === "star" && (
              <button onClick={onNext} className={`absolute bottom-16 left-1/2 transform -translate-x-1/2 orb h-10 w-10 ${isPulsing ? 'pulse hover:animate-none' : 'opacity-50'}`}>{'\u25BC\uFE0E'}</button>
            )}
          </div>
          
          {/* <div className="mt-4 h-24 mb-8 text-center flex flex-col justify-start">
            {(entity.location || isSelf) && <p onClick={() => { startUpdatingEntity("location"); }} className={`text-xl text-white mb-1 line-clamp-1 overflow-ellipsis ${isSelf ? 'fade' : ''}`}>
              {entity.location || "Add location"}
            </p>}
            {(entity.bio || isSelf) && (
              isEditingBio ? (
                <textarea
                  value={editedBio}
                  onChange={handleBioChange}
                  onBlur={handleBioBlur}
                  onKeyDown={handleBioKeyDown}
                  className={`foreground-gradient-1 italic mb-1 text-lg w-full bg-transparent border border-yellow-400 rounded p-1`}
                  rows={2}
                  autoFocus
                />
              ) : (
                <p 
                  onClick={handleBioClick} 
                  className={`foreground-gradient-1 italic mb-1 text-lg line-clamp-2 overflow-ellipsis ${isSelf ? 'fade' : ''}`}
                >
                  {entity.bio || "Add bio"}
                </p>
              )
            )}
            {entity.question && <p onClick={() => { startUpdatingEntity(entity); }} className={`foreground-gradient-1 italic mb-1 text-sm whitespace-pre-line fade`}>
              {entity.update || "<Write answer>"}
            </p>}
            {!entity.bio && (
              <button onClick={() => { startUpdatingEntity("bio"); }} className="border mt-2 border-white text-white px-2 py-1 rounded inline-block w-auto">Add bio</button>
            )}
            <p onClick={() => { startUpdatingEntity("dob"); }} className="foreground-gradient-1 mb-1">
              {entity.dob ? `born on ${dateFormat(Date.parse(entity.dob), "mmm d, yyyy")}` : "Add birthday"}
            </p>
          </div> */}
        </div>
      )}
    </>
  );
}