import React from 'react';

import Entity from './Entity';

import { getCloudinaryUrl, getOptimalImageSize } from './helper';

export default function Load({ isData, isLogin, isPortraitMode, setIsTermsAndPrivacyOpen, selectedEntity }) {

  const generateLoadingEntities = (count, type) => {
    const entities = [];
    for (let i = 0; i < count; i++) {
      entities.push(
        <Entity
          key={`load-${type}-${i}`}
          mode="load"
          type={type}
          isPortraitMode={isPortraitMode}
        />
      );
    }
    return entities;
  };

  const handleButtonClick = () => {
    window.open('https://wa.me/12345178901?text=Visualize+VERSE', '_self');
  };

  const handleTermsClick = () => {
    setIsTermsAndPrivacyOpen(true);
  };

  return (
    <div>

      {/* Background */}
      <div
        className="fixed inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${getCloudinaryUrl(
            "web/enlarged-background-sharpened-darkened", 
            isPortraitMode ? getOptimalImageSize(Math.max(window.innerWidth, window.innerHeight)) * 3 : undefined, 
            isPortraitMode ? undefined : getOptimalImageSize(Math.max(window.innerWidth, window.innerHeight)), 
            isPortraitMode ? 'a_90' : ''
          )})`,
          transformOrigin: 'center center',
          backgroundPosition: 'center center'
        }}
      ></div>

      {/* Splash & Login */}
      {!isData &&
        <div className={`fixed inset-0 h-full flex ${isPortraitMode ? 'flex-col mb-20' : ''} animate-fadeIn`}>
          
          {/* Splash */}
          <div className={`${isPortraitMode ? 'h-1/2 w-full' : 'w-1/2 h-full'} flex flex-col justify-center items-center`}>
            <div className="relative h-full w-full flex justify-center items-center">
              {generateLoadingEntities(isPortraitMode ? 50 : 100, "star")}
            </div>
            <div className={`absolute ${isPortraitMode ? 'left-1/2 -translate-x-1/2' : 'top-1/2 -translate-y-1/2'} w-full`}>
              <p className={`m-0 py-1.5 text-white ${isPortraitMode ? 'text-2xl' : 'text-3xl'} italic pr-1 text-center glow-text`}>{isLogin ? "Expand your Universe" : "Expanding your Universe"}</p>
              <p className={`m-0 py-1.5 foreground-gradient-1 ${isPortraitMode ? 'text-xl' : 'text-2xl'} italic pr-1 text-center`}>at the speed of light.</p>
            </div>
          </div>
          <div className={`${isPortraitMode ? 'h-1/2 w-full' : 'w-1/2 h-full'} flex flex-col justify-center items-center`}>
            <div className="relative h-full w-full flex justify-center items-center">
              {generateLoadingEntities(isPortraitMode ? 50 : 100, "neuron")}
            </div>
            <div className={`absolute ${isPortraitMode ? 'left-1/2 -translate-x-1/2' : 'top-1/2 -translate-y-1/2'} w-full`}>
              <p className={`m-0 py-1.5 text-white ${isPortraitMode ? 'text-2xl' : 'text-3xl'} italic pr-1 text-center glow-text`}>{isLogin ? "Enrich your Mind" : "Enriching your Mind"}</p>
              <p className={`m-0 py-1.5 foreground-gradient-1 ${isPortraitMode ? 'text-xl' : 'text-2xl'} italic pr-1 text-center`}>at the speed of thought.</p>
            </div>
          </div>

          {/* Login */}
          {isLogin && (
            <>
              <div className={`absolute ${isPortraitMode ? 'top-0 left-0 m-4' : 'top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2'}`}>
                <div className="flex flex-col justify-center items-center">
                  <img 
                    src={getCloudinaryUrl("complete-logo", isPortraitMode ? 128 : 256)} 
                    alt="VERSE Logo" 
                    className={`max-w-xs ${isPortraitMode ? 'max-h-16' : 'max-h-64'}`} 
                  />
                  {!isPortraitMode && <p className="foreground-gradient-0 text-3xl italic pr-1">Social network for the extremely busy</p>}
                </div>
              </div>
              <div className={`absolute ${isPortraitMode ? 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full' : 'bottom-1/4 left-1/2 transform -translate-x-1/2'}`}>
                <div className="flex flex-col justify-center items-center mt-16">
                  <div className="flex justify-center items-center">
                    <button onClick={handleButtonClick}>
                      <img src={getCloudinaryUrl("whatsapp-logo", 64, 64)} alt="WhatsApp Logo" className="w-8 h-8 mr-2" />
                      <span className="text-gray-500 font-semibold px-8">Sign in with WhatsApp</span>
                    </button>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-white text-opacity-50 text-sm mt-4 mb-1">Please consider reviewing our</p>
                    <p onClick={handleTermsClick} className="foreground-gradient-2 fade">Terms & Privacy Policy</p>
                  </div>
                </div>
              </div>
              <div className="absolute bottom-0 w-full text-center p-4">
                <p className="text-white text-opacity-50 text-sm">© 2024 Dextra Arts, Inc.</p>
              </div>
            </>
          )}
        </div>
      }

      {/* Progress */}
      {isData &&
        <div className={`fixed ${!isPortraitMode && selectedEntity ? `inset-y-0 ${selectedEntity.name ? 'right-0 w-2/5' : 'left-0 w-2/5'}` : 'inset-0'} z-10 flex justify-center items-center`}>
          <div className="absolute animate-spin-entity-1">
            <div className="w-5 h-5 orb"></div>
          </div>
          <div className="absolute animate-spin-entity-2">
            <div className="w-4 h-4 diamond"></div>
          </div>
        </div>
      }
    </div>
  );
};